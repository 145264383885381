//
// Landing page
// --------------------------------------------------

.round-button-img {
  width: 80%;
  height: 80%;
  border-radius: 50%;
  border:10px solid #cfdcec;
  box-shadow: 0 0 3px gray;
  opacity: 0.8;
  filter: alpha(opacity=80);
}
.round-button-img:hover {
    opacity: 1;
    filter: alpha(opacity=100);
}
.navbar-nav > li > span {
  margin-top: 13px;
  display: inline-block;
}
.p-t-100{
	padding-top:100px;
}
.wrapper-10{
	padding: 10px;
}